<template>
  <div>
    <!-- <div class="d-flex flex-row justify-content-between primary-bg shadow-md text-white">
      <div class="d-flex flex-row p-2">
        <img src="../../assets/_logo.png" style="max-height: 80px" class="shadow-sm" />
        <div class="pl-2 py-2">
          <div class="pl-2 mb-1">
            <img src="../../assets/flag.png" style="max-height: 25px" />
            <strong class="mx-3 py-1 text-white">République du Mali</strong>
          </div>
          <strong class="h4 mx-2 text-white">{{ $t("project_title") }}</strong>
        </div>
      </div>
      <div class="px-2 py-1 ">
        <h2 class="mb-1">SIG-DJ</h2>
        <small>Système Intégré de Gestion des Dossiers Judiciaires</small>
      </div>
    </div> -->
    <div  class="d-flex flex-row justify-content-center"> 
      <img src="../../assets/ban.png"  class="img-fluid "/>
    </div>

    <div
      v-if="isLoggedIn"
      class="d-flex flex-row justify-content-between  secondary-bg"  
    >
      <div class="d-flex align-items-center">
        <button 
          class="toggle-btn d-flex align-items-center justify-content-center"
          @click="$emit('toggle-sidebar')"
        >
          <vs-icon
            class="material-icons-outlined "
            :icon="isSidebarCollapsed ? 'menu' : 'menu_open'"
            size="20px"
          />
        </button>
        <!-- <small class="mx-2">
          <strong class="h6 py-2 primary-text">{{ $t("welcome_text") }}</strong>
        </small> -->
      </div>

      <div class="mx-2 py-2">
        <span class="el-dropdown-link">
          <i class="el-icon-office-building primary-text  mx-2" style="font-size: 16px;"></i>
          <strong class="mx-2 h6 primary-text ">{{ userApi.getJurisdictionName }}</strong>
        </span>

        <span class="el-dropdown-link">
          <i class="el-icon-suitcase primary-text  mx-2" style="font-size: 16px;"></i>
          <strong class="mx-2 h6 primary-text ">{{ userApi.getChamberName }}</strong>
        </span>

        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-user-solid primary-text  mx-2" style="font-size: 16px;"></i>
            <strong class="mx-2 h6 primary-text ">{{ userApi.getUserName }}</strong>
            <i class="el-icon-arrow-down el-icon--right primary-text " style="font-size: 16px;"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-s-platform" command="reset_password">
              {{ $t("my_account") }}
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-unlock" command="logout">
              {{ $t("logout") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-menu
      v-else
      :router="true"
      class="d-flex flex-row justify-content-end menu-horizontal secondary-bg secondary-bg"
      mode="horizontal"
      text-color="black"
      active-text-color="#016ec1"
    >
      <el-menu-item index="1" class="d-flex flex-row menu-item" :route="{ name: 'home' }">
        <vs-icon
          class="material-icons-outlined primary-text"
          icon="home"
          size="20px" style="margin-top:10px"
        ></vs-icon>
        <span class="menu-text">{{ $t("home") }}</span>
      </el-menu-item>

      <el-menu-item
        index="2"
        class="d-flex flex-row menu-item"
        :route="{ name: 'public-audiences' }"
      >
        <vs-icon
          class="material-icons-outlined primary-text"
          icon="article"
          size="20px" style="margin-top:10px"
        ></vs-icon>
        <span class="menu-text">{{ $t("audience_roles") }}</span>
      </el-menu-item>

      <el-menu-item index="3" class="d-flex flex-row menu-item" :route="{ name: 'login' }">
        <vs-icon
          class="material-icons-outlined primary-text"
          icon="login"
          size="20px" style="margin-top:10px"
        ></vs-icon>
        <span class="menu-text">{{ $t("login") }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
import { useUserStore } from "@/store/UserService";
export default {
  data() {
    return {
      userApi: useUserStore(),
    };
  },
  props: {
    isLoggedIn: Boolean,
    isSidebarCollapsed: Boolean
  },
  methods: {
    logout() {
      localStorage.clear();
      this.userApi.setLoggedIn(false);
      this.userApi.setChain("");
      this.userApi.setChamberId(null);
      this.userApi.setChamberName("");
      this.$router.push("/");
    },
    handleCommand(command) {
      if (command == "logout") {
        this.logout();
      }
      if (command == "reset_password") {
        this.$router.push("/users/" + this.userApi.getUserId + "/reset_password");
      }
    },
  },
};
</script>
<style scoped>
.primary-bg {
  background: #016ec1;
}

.secondary-bg {
  background: rgb(249, 236, 186);
}

.primary-text {
  color: #016ec1;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.toggle-btn {
  width: 32px;
  height: 32px;
  border: none;
  background: rgba(1, 110, 193, 0.1);
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
  padding: 0;
  transition: background-color 0.2s;
}

.toggle-btn:hover {
  background: rgba(1, 110, 193, 0.2);
}

.toggle-btn:active {
  background: rgba(1, 110, 193, 0.3);
}

.el-menu :hover {
  background: none !important;
}

.menu-horizontal .menu-item {
  height: 40px !important;
  line-height: 40px !important;
  padding: 0 16px !important;
}

.menu-horizontal .menu-text {
  font-size: 14px !important;
  font-weight: normal !important;
  margin-left: 8px;
}

:deep(.el-menu--horizontal > .el-menu-item) {
  height: 40px !important;
  line-height: 40px !important;
}
</style>
