<template>
  <div class="receiver-component" v-loading="loading">
    <!-- En-tête du composant -->
    <div class="component-header">
      <h4 class="title">{{ $t('transmission_details') }}</h4>
      <p class="description">{{ $t('transmission_description') }}</p>
    </div>

    <el-divider />

    <!-- Bloc d'information -->
    <el-alert
      type="info"
      :title="$t('transmission_info_title')"
      :description="$t('transmission_info_description')"
      show-icon
      :closable="false"
      class="mb-4"
    />

    <el-row :gutter="20" v-if="jurisdictions != null && selectedJurisdictionId != null">
      <!-- Section Juridiction -->
      <el-col :span="12">
        <div  
             class="jurisdiction-section selection-block">
          <div class="field-header">
            <label class="required-field">{{ $t("jurisdiction") }}</label>
            <el-tooltip effect="dark" :content="$t('jurisdiction_selection_help')" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          
          <div class="selection-container">
            <p class="selection-hint">{{ $t('jurisdiction_selection_hint') }}</p>
            <el-select
              class="w-100"
              v-model="selectedJurisdictionId"
              filterable
              :placeholder="$t('select_jurisdiction')"
            >
              <el-option
                v-for="item in jurisdictions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <span class="jurisdiction-option">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
      </el-col>

      <!-- Section Destinataire -->
      <el-col :span="12">
        <div class="receiver-section selection-block">
          <div class="field-header">
            <label class="required-field">{{ $t("receiver") }}</label>
            <el-tooltip effect="dark" :content="$t('receiver_selection_help')" placement="top">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>

          <div class="selection-container">
            <p class="selection-hint">{{ $t('receiver_selection_hint') }}</p>
            <el-select
              class="w-100"
              v-model="receiverId"
              filterable
              :placeholder="$t('select_receiver')"
              @change="$emit('input', receiverId)"
            >
              <el-option
                v-for="item in Users"
                :key="item.id"
                :label="item.id == userApi.getUserId ? $t('my_self') : item.name + ' (' + item.positionName + ')'"
                :value="item.id"
              >
                <div class="receiver-option">
                  <span class="receiver-name">
                    {{ item.id == userApi.getUserId ? $t('my_self') : item.name }}
                  </span>
                  <small class="receiver-position">{{ item.positionName }}</small>
                </div>
              </el-option>
            </el-select>

            <!-- Message d'aide -->
            <small class="help-text" v-if="Users.length === 0">
              {{ $t('no_available_receivers') }}
            </small>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- Footer avec informations supplémentaires -->
    <div class="component-footer">
      <el-alert
        type="warning"
        :title="$t('transmission_warning_title')"
        :description="$t('transmission_warning_description')"
        show-icon
        :closable="false"
        
      />
    </div>
  </div>
</template>

<script>
import { useTrameStore } from "@/store/TrameService";
import { useUserStore } from "@/store/UserService";
import { useJurisdictionStore } from "@/store/JurisdictionService";
export default {
  data() {
    return {
      loading: false,
      receiverId: this.value,
      userApi: useUserStore(),
      users: [],
      trameApi: useTrameStore(),
      profiles: [],
      jurisdictionApi: useJurisdictionStore(),
      jurisdictions: null,
      selectedJurisdictionId:parseInt(this.jurisdictionId),
    };
  },
  props: ["trameId", "value", "jurisdictionId"],
  update() {
    this.$emit("input", this.value);
  },
  async created() {
    this.getUsers();
    this.getProfiles();
    this.getJurisdictions();
  },
  watch:{
    selectedJurisdictionId: function() { // watch it
      this.getUsers();
    }
  },
  computed: {
    Users: function () {
      return this.users.filter((user) => {
        return (
          user.id != this.userApi.getUserId &&
          this.profiles.some((profile) => profile.toProfileId === user.positionId)
        );
      });
    },
    JurisdictionId: function () {
      return this.jurisdictionId;
    }
  },
  methods: {
    getUsers() {
      this.loading = true;
      this.jurisdictionApi.fetchUsers(this.selectedJurisdictionId).then(() => {
        this.users = this.jurisdictionApi.getUsers;
        this.loading = false;
      });
    },
    getProfiles() {
      this.trameApi.fetchProfiles(this.trameId).then(() => {
        this.profiles = this.trameApi.getProfiles;
      });
    },
    async getJurisdictions() {
      this.loading = true;
      await this.jurisdictionApi.index();
      this.jurisdictions = this.jurisdictionApi.getItems;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.receiver-component {
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
}

.jurisdiction-section,
.receiver-section {
  margin-bottom: 1px;
}

.field-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.required-field::after {
  content: '*';
  color: #f56c6c;
  margin-left: 4px;
}

.field-header label {
  font-weight: 500;
  color: #606266;
  margin-right: 8px;
}

.el-icon-question {
  color: #909399;
  font-size: 14px;
  cursor: help;
}

.receiver-option {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
}

.receiver-name {
  color: #303133;
  font-weight: 500;
}

.receiver-position {
  color: #909399;
  font-size: 0.85em;
  margin-top: 2px;
}

.help-text {
  display: block;
  color: #909399;
  font-size: 0.85em;
  margin-top: 8px;
  font-style: italic;
}

.jurisdiction-option {
  padding: 8px 0;
}

:deep(.el-select) {
  width: 100%;
}

:deep(.el-select-dropdown__item) {
  padding: 0 12px;
}

:deep(.el-select .el-input__inner) {
  border-radius: 4px;
}

:deep(.el-select .el-input__inner:hover) {
  border-color: #409EFF;
}

.w-100 {
  width: 100%;
}

.component-header {
  margin-bottom: 1.5rem;
}

.title {
  color: #303133;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.description {
  color: #606266;
  font-size: 0.9rem;
  line-height: 1.4;
}

.selection-block {
  background: #fff;
  border-radius: 6px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.selection-container {
  background: white;
  padding: 0.5rem 0;
  border-radius: 4px;
}

.selection-hint {
  color: #606266;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  line-height: 1.4;
}

.component-footer {
  margin-top: 2rem;
}

:deep(.el-alert) {
  margin-bottom: 1.5rem;
}

:deep(.el-row) {
  margin: 0 -10px;
}

:deep(.el-col) {
  padding: 0 10px;
}
</style>