<template>
  <div class="w-100">

    <!-- je veux afficher la liste des document dans une description element plus comme plus bass --> 
    <el-descriptions class="m-5" :column="1"  size="mini" v-if="documents.length" :title="$t('documents_to_generate_or_edit')">
      <el-descriptions-item :label="document.documentModelName" v-for="document in documents" :key="document.id">
        <div v-if="isDocumentAlreadyGenerated(document) == null">
          <el-button size="mini" type="text" @click="generateDocument(document)">{{ $t("produce_document") }}</el-button>
        </div>
        <div v-else>
          <el-button size="mini" type="text" @click="updateDocument(document)">{{ $t("update_document") }}</el-button>
        </div>
      </el-descriptions-item>
    </el-descriptions>


    <el-dialog title="" :visible.sync="showDocumentGeneration" width="70%" destroy-on-close :modal="false">
      <document-geneneration-component
        :investigation="investigation"
        :investigationApi="investigationApi"
        :document="document"
        :injections="injections"
        @change="closeDialog"
      />
    </el-dialog>

  </div>
</template>
<script>
import DocumentGenenerationComponent from "./components/DocumentGenenerationComponent.vue";
export default {
  props: ["investigation", "investigationApi", "trame", "trameApi"],
  components: {
    DocumentGenenerationComponent,
  },
  inject: ['injections'],
  data() {
    return {
      investigationDocuments: [],
      documents: [],
      document: null,
      showDocumentGeneration: false,
    };
  },
  created() {
    console.log(this.injections)
    this.getInvestigationDocuments(),
    this.getDocuments();
  },
  methods: {
    getDocuments() {
      this.trameApi.fetchDocuments(this.$route.params.id).then(() => {
        this.documents = this.trameApi.getDocuments;
      });
    },
    getInvestigationDocuments() {
      this.loading = true;
      this.investigationApi.fetchDocuments(this.investigation.id).then(() => {
        this.loading = false;
        this.investigationDocuments = this.investigationApi.getDocuments;
      });
    },
    generateDocument(document) {
      this.document = document;
      this.showDocumentGeneration = true;
    },
    updateDocument(document) {
      this.document = this.isDocumentAlreadyGenerated(document);
      this.document.documentModelContent =  this.document.content;
      this.showDocumentGeneration = true;
    },
    closeDialog() {
      this.document = null;
      this.showDocumentGeneration = false;
    },
    isDocumentAlreadyGenerated(document) {
      return this.investigationDocuments.find(doc => doc.documentModelId === document.documentModelId) || null;
    }
  },
};
</script>
<style scoped>
:deep(.el-descriptions__header) {
  margin-bottom: 1rem;
}

:deep(.el-descriptions-item__label) {
  min-width: 200px;
  padding-top: 11px;
  font-size: 13px;
}

:deep(.el-descriptions-item__content) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

</style>
