import { defineStore } from "pinia";
import http from "./http.js";

export const useTrameStore = defineStore("trame", {
  state: () => ({
    requestStatus: "",
    error: "",
    trames: [],
    trame: null,
    derivedTrames: [],
    derivedTrame: null,
    profiles: [],
    documents: [],
    totalElements: 0, // Pour la pagination
    totalPages: 0, // Pour la pagination
    currentPage: 0, // Page actuelle
    pageSize: 10, // Taille de la page
    formTemplates: [], // Pour stocker les templates
    currentTemplate: null,
    groupMembers: [],
    trameGroups: []
  }),

  getters: {
    getRequestStatus(state) {
      return state.requestStatus;
    },
    getItems(state) {
      return state.trames;
    },
    getItem(state) {
      return state.trame;
    },
    getDerivedTrames(state) {
      return state.derivedTrames;
    },
    getDerivedTrame(state) {
      return state.derivedTrame;
    },
    getProfiles(state) {
      return state.profiles;
    },
    getDocuments(state) {
      return state.documents;
    },
    getTotalElements(state) {
      return state.totalElements;
    },
    getTotalPages(state) {
      return state.totalPages;
    },
    getCurrentPage(state) {
      return state.currentPage;
    },
    getPageSize(state) {
      return state.pageSize;
    },
    getFormTemplates: (state) => state.formTemplates,
    getCurrentTemplate: (state) => state.currentTemplate,
    getGroupMembers: (state) => state.groupMembers,
    getTrameGroups: (state) => state.trameGroups
  },

  actions: {
    async index(page = 0, size = 1000, searchQuery = "") {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get(
          `trames?page=${page}&size=${size}&search=${searchQuery}`
        );
        const {data, currentPage, totalPages, totalElements, pageSize  } = response.data;
        this.trames = data;
        this.totalElements = totalElements;
        this.totalPages = totalPages;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.handleHttpError(error);
      }
    },

    async store(data) {
      this.requestStatus = "LOADING";
      return http
        .post("trames", data)
        .then((response) => {
          this.trame = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    show(id) {
      this.requestStatus = "LOADING";
      return http
        .get("trames/" + id)
        .then((response) => {
          this.trame = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    update(data) {
      this.requestStatus = "LOADING";
      return http
        .put("trames", data)
        .then((response) => {
          this.trame = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    delete(id) {
      this.requestStatus = "LOADING";
      return http
        .delete("trames/" + id)
        .then(() => {
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    fetchDerivedTrames(id) {
      this.requestStatus = "LOADING";
      return http
        .get("trames/" + id + "/derivedTrames")
        .then((response) => {
          this.derivedTrames = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    addDerivedTrame(id, data) {
      this.requestStatus = "LOADING";
      return http
        .post("trames/" + id + "/derivedTrames", data)
        .then((response) => {
          this.derivedTrame = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    deleteDerivedTrame(trameId, derivedTrameId) {
      this.requestStatus = "LOADING";
      return http
        .delete("trames/" + trameId + "/derivedTrames/" + derivedTrameId)
        .then(() => {
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    fetchProfiles(id) {
      this.requestStatus = "LOADING";
      return http
        .get("trames/" + id + "/profiles")
        .then((response) => {
          this.profiles = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    fetchDocuments(id) {
      this.requestStatus = "LOADING";
      return http
        .get("trames/" + id + "/documents")
        .then((response) => {
          this.documents = response.data;
          this.requestStatus = "SUCCESS";
        })
        .catch((error) => {
          this.handleHttpError(error);
        });
    },
    handleHttpError(error) {
      this.requestStatus = "FAILED";
      if (error.response) {
        // Server responded with a status code
        this.error = error.response.data.message || "An error occurred";
      } else if (error.request) {
        // Request was made but no response was received
        this.error = "No response received from server";
      } else {
        // An error occurred in setting up the request
        this.error = error.message || "An error occurred";
      }
    },
    async createTemplate(trameId, templateData) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.post(`trames/${trameId}/templates`, templateData);
        this.currentTemplate = response.data;
        this.requestStatus = "SUCCESS";
        return response.data;
      } catch (error) {
        this.handleHttpError(error);
      }
    },
    async getTemplate(trameId) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get(`trames/${trameId}/templates`);
        this.currentTemplate = response.data;
        this.requestStatus = "SUCCESS";
        return response.data;
      } catch (error) {
        this.handleHttpError(error);
      }
    },
    async updateTemplate(trameId, templateData) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.put(`trames/${trameId}/templates/${templateData.id}`, templateData);
        this.currentTemplate = response.data;
        this.requestStatus = "SUCCESS";
        return response.data;
      } catch (error) {
        this.handleHttpError(error);
      }
    },
    async deleteTemplate(trameId) {
      this.requestStatus = "LOADING";
      try {
        await http.delete(`trames/${trameId}/templates`);
        this.currentTemplate = null;
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.handleHttpError(error);
      }
    },
    async addTrameToGroup(groupId, trameId) {
      this.requestStatus = "LOADING";
      try {
        await http.post(`trames/${groupId}/members/${trameId}`);
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.handleHttpError(error);
      }
    },
    async removeTrameFromGroup(groupId, trameId) {
      this.requestStatus = "LOADING";
      try {
        await http.delete(`trames/${groupId}/members/${trameId}`);
        this.requestStatus = "SUCCESS";
      } catch (error) {
        this.handleHttpError(error);
      }
    },
    async fetchGroupMembers(groupId) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get(`trames/${groupId}/members`);
        this.groupMembers = response.data;
        this.requestStatus = "SUCCESS";
        return response.data;
      } catch (error) {
        this.handleHttpError(error);
      }
    },
    async fetchTrameGroups(trameId) {
      this.requestStatus = "LOADING";
      try {
        const response = await http.get(`trames/${trameId}/groups`);
        this.trameGroups = response.data;
        this.requestStatus = "SUCCESS";
        return response.data;
      } catch (error) {
        this.handleHttpError(error);
      }
    },
    async updateTrameIsGroup(trameId, isGroup) {
      this.requestStatus = "LOADING";
      try {
        const trame = await this.show(trameId);
        trame.isGroup = isGroup;
        const response = await this.update(trame);
        this.requestStatus = "SUCCESS";
        return response;
      } catch (error) {
        this.handleHttpError(error);
      }
    }
  }
});
