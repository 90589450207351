<template>
  <div class="p-2">
    <div class="d-flex justify-content-end py-2">
   

      <el-button type="text" @click="generateDocument" size="small" class="mx-3">{{
        this.document.investigationId == null
          ? $t("generate_document")
          : $t("update_document")
      }}</el-button>
    </div>

    
    <vue2-tinymce-editor
      v-model="content"
      :options="editorConfig"
      class="tinymce-editor"
      :height="1500"
    ></vue2-tinymce-editor>

    <div class="d-flex justify-content-end py-2">
      <el-button type="text" @click="generateDocument" size="small">{{
        this.document.investigationId == null
          ? $t("generate_document")
          : $t("update_document")
      }}</el-button>
    </div>
  </div>
</template>
<script>
import { Vue2TinymceEditor } from "vue2-tinymce-editor";
import frFR from "@/assets/fr_FR.js";
import { useInvestigationGeneratedDocumentStore } from "@/store/InvestigationGeneratedDocumentService";
import { useUserStore } from '@/store/UserService';
export default {
  components: {
    Vue2TinymceEditor,
  },
  data() {
    return {
      userApi:useUserStore(),
      api: useInvestigationGeneratedDocumentStore(),
      content: this.replaceKeysInContent(this.document.documentModelContent),
      editorConfig: {
        language: "fr_FR", // Langue française
        language_pack: frFR,
        menubar: true, // Afficher la barre de menu
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        menu: {
          file: { title: "Fichier", items: "open save | print" }, // Supprime l'option "Nouveau" (enlève "new")
          edit: { title: "Édition", items: "undo redo | cut copy paste" }, // Ajoutez d'autres éléments du menu si nécessaire
        },
        toolbar:
          "undo redo | formatselect | " +
          "bold italic underline strikethrough | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "table | removeformat | help",
        content_style: `
                  body {
                    margin: 2cm; /* Marges du document */
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 1.6;
                    color: #000;
                    background: #fff;
                    border: 1px solid #ccc;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                    padding: 1cm; /* Espace intérieur */
                  }
                  h1 {
                    font-size: 24px;
                    margin-bottom: 0.5cm;
                  }
                  p {
                    margin-bottom: 1cm;
                  }
                `,
        table_default_attributes: {
          border: "1",
        },
        table_default_styles: {
          width: "100%",
          borderCollapse: "collapse",
        },
      },
      legendTable: [
        { name: "@JURIDICTION", comment: "Nom de la juridiction associée à l'enquête." },
        {
          name: "@AUTEUR",
          comment: "Nom de la personne ayant rédigé ou généré le document.",
        },
        { name: "@RG", comment: "Le numéro de rôle de l'affaire." },
        {
          name: "@RP",
          comment: "Le numéro de rôle de l'affaire (identique à RG pour ce cas).",
        },
        { name: "@AFFAIRE", comment: "Nom de l'affaire en cours." },
        {
          name: "@DATE",
          comment:
            "Date de création ou de génération du document (format: jour mois année).",
        },
      ],
    };
  },
  props: ["document", "investigation", "investigationApi", "injections"],
  methods: {
    replaceKeysInContent(content) {
      // On parcourt chaque clé de l'objet `injections` en utilisant `map`
      Object.entries(this.injections).map(([key, value]) => {
        // Vérifier si la valeur n'est pas null avant de procéder au remplacement
        if (value !== null) {
          // Remplacer chaque occurrence de la clé (préfixée par '@') dans le contenu par sa valeur
          content = content.replace(new RegExp(`@${key}`, "g"), value); // Utilisation de 'g' pour remplacer toutes les occurrences
        }
      });
      return content;
    },
    generateDocument() {
      let formatData = {
        userId:this.userApi.getUserId,
        investigationId: this.investigation.id,
        documentModelId: this.document.documentModelId,
        content: this.content,
      };
      if (this.document.investigationId != null) {
        formatData.id = this.document.id;
        this.api.update(formatData).then(() => {
          this.$notify({
            title: "Document enrégistré!",
            message: "le document à été mis à jour avec succès",
            type: "success",
          });
          this.$emit("change");
        });
      } else {
        this.api.store(formatData).then(() => {
          this.$notify({
            title: "Document enrégistré!",
            message: "le document à été enregistré avec succès",
            type: "success",
          });
          this.$emit("change");
        });
      }
    },
  },
};
</script>
