<template>
  <div class="container-fluid px-0 mx-0">
    <top-menu-component
      :isLoggedIn="api.isLoggedIn"
      :isSidebarCollapsed="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      style="position: fixed; top: 0; z-index:1; width: 100%"
    />
    <div
      class="d-flex flex-row justify-content-between background-container"
      style="margin-top: 200px !important"
    >
      <sidebar-component 
        v-if="api.isLoggedIn" 
        :isCollapsed="sidebarCollapsed"
      />
      <div
        :style="getMainContentStyle"
        class="container-fluid pt-3 px-0"
      >
        <div class="container-fluid px-0"> 
          <transition name="slide-fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
    <footer-component class="mt-5" style="position: fixed; bottom: 0; z-index: 999999" />
  </div>
</template>

<script>
import { useUserStore } from "./store/UserService";

export default {
  data() {
    return {
      api: useUserStore(),
      sidebarCollapsed: false,
      isOnMobile: false,
    };
  },
  computed: {
    getMainContentStyle() {
      const sidebarWidth = this.sidebarCollapsed ? 81 : 252;
      return {
        right: 0,
        position: 'fixed',
        height: '100%',
        overflow: 'scroll',
        paddingBottom: '300px',
        left: this.api.isLoggedIn ? `${sidebarWidth}px` : '0',
        width: this.api.isLoggedIn 
          ? `calc(100% - ${sidebarWidth}px)`
          : '100%',
        transition: 'left 0.3s ease, width 0.3s ease'
      }
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    }
  }
};
</script>

<style>
.vs-table--header {
  background: #c0c0c040 !important;
  padding-right: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.bg-light-pci {
  background: #c0c0c040 !important;
}
.bg-pci {
  background-color: rgb(56 189 248);
}
.font-pci {
  color: rgb(56 189 248);
}

.pci_theme .vdatetime-popup__header,
.pci_theme .vdatetime-calendar__month__day--selected > span > span,
.pci_theme .vdatetime-calendar__month__day--selected:hover > span > span {
  background: rgb(56 189 248);
}

.pci_theme .vdatetime-year-picker__item--selected,
.pci_theme .vdatetime-time-picker__item--selected,
.pci_theme .vdatetime-popup__actions__button {
  color: rgb(56 189 248);
}

.background-container {
  position: relative;
  overflow: scroll;
  height: 100%; /* Ajustez la hauteur selon vos besoins */
}

/* Créez un pseudo-élément pour afficher l'image en arrière-plan */
.background-container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-image: url("assets/background.jpg");  Spécifiez le chemin de votre image */
  background-size: contain; /* Ajustez la taille de l'image pour remplir l'arrière-plan */
  background-position: center; /* Centrez l'image */
  opacity: 0.1; /* Transparence de 50% */
  z-index: -1;
}

.bg-white {
  background: #fff;
}
.vs-tabs--content {
  position: relative;
  width: 100%;
  display: block;
  padding: 0px !important;
}
.con-vs-popup .vs-popup {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 100;
  width: 900px !important;
  margin: 10px;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-animation: rebound 0.3s;
  animation: rebound 0.3s;
}

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600");

body,
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  background-color: #fff;
  color: #262626;
  margin:0px !important;
}

/* #app {
  padding-left: 350px;
  transition: 0.3s ease;
}

#app.loggedIn {
  padding-left: 350px;
  transition: 0.3s ease;
}
#app.loggedOut {
  padding-left: 0px;
  transition: 0.3s ease;
}
#app.collapsed {
  padding-left: 50px;
}
#app.onmobile {
  padding-left: 50px;
} */

.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}

/* .demo {
  padding: 50px;
} */

/* .container {
  max-width: 900px;
} */

pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
.v-sidebar-menu .vsm--icon {
  background-color: rgb(56 189 248) !important;
  padding: 3px;
}
.blue-light-text-color {
  color: rgb(56 189 248) !important;
}
.blue-light-bgt-color {
  background-color: rgb(56 189 248) !important;
}
.border-primary {
  border-color: rgb(56 189 248) !important;
}
/* .v-sidebar-menu .vsm--link.vsm--link_exact-active {
  background: orange;
} */
.el-button--text {
  color: rgb(56 189 248)!important;
}
.el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: deepskyblue!important;
  color: white !important;
}
/* Container styling */
.container {
  /* max-width: 1200px; */
  margin: 0 auto;
}

/* Header styling */
.header {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 20px;
}

/* Icon button styling */
.icon-button .el-icon-circle-plus-outline {
  color: #409eff;
}

/* Action button styling */
.action-button {
  text-decoration: none;
  color: #409eff;
}
.el-button--primary {
  color: #FFFFFF;
  background-color: #409eff!important;
  border-color: #409eff!important;
}


/* Transition styles */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Animation styles */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(30px); /* Translation vers le bas */
}

/* Optionnel : Ajoutez un effet de zoom */
.slide-fade-enter {
  transform: scale(0.9); /* Zoom avant l'entrée */
}
.slide-fade-leave-to {
  transform: scale(1.1); /* Zoom après la sortie */
}
</style>
